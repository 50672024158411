import React from 'react';
import { useMatches, Link, useParams } from 'react-router-dom';
import { HomeIcon, RightArrow } from '../../Images/svgs'
import {truncateText} from "../../axios-client";

const useBreadcrumbs = () => {
    const matches = useMatches();
    const params = useParams(); // Use useParams to get params

    const breadcrumbs = matches.map(({ path, handle }) => ({
        path,
        crumb: handle && handle.crumb ? handle.crumb(params[Object.keys(params)[0]]) : null,
    }));

    return breadcrumbs;
};

const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();

    return (
        <>
            <nav className='flex flex-col justify-center items-end px-16 py-4 text-sm font-medium text-right text-white bg-blue-600 max-md:px-5'>
                <ul className='container max-w-7xl mx-auto md:flex md:flex-row items-center self-start gap-4 px-1.5 py-1'>
                    <Link to='/'><HomeIcon /></Link>
                    <RightArrow />
                    {breadcrumbs
                        .filter(breadcrumb => breadcrumb.crumb)
                        .map((breadcrumb, index, array) => (
                            <React.Fragment key={index}>
                                <li>
                                    <Link className='grow whitespace-nowrap' to={breadcrumb.path}>{truncateText(breadcrumb.crumb?.length >10 &&breadcrumb.crumb ,10)}</Link>
                                </li>
                                {index !== array.length - 1 && <RightArrow />}
                            </React.Fragment>
                        ))}
                </ul>
            </nav>
        </>
    );
};

export default Breadcrumbs;
