import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import Router from './Router';
import './css/index.css';
import './css/custom.css';
import { createRoot } from 'react-dom/client';

import 'react-quill/dist/quill.snow.css';
import { ContextProvider } from './Providers/ContextProvider';
import {Provider} from 'react-redux';
import store from './Managment/store';
import 'react-toastify/dist/ReactToastify.css';
import './css/bottomNav.css';
import Loader from './Components/Loader/Loader';
import { toast, ToastContainer } from 'react-toastify';
import axiosClient, {getCurrencyLocal, getLocalLang, getToken} from './axios-client';
import FirstData from "./FirstData";
import SEOComponent from './Components/SEOComponent';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {

    useEffect(() => {
        const handleChangedCurrency = () => {
            if(getToken() && getToken()!=='' && getToken() !== null){
            axiosClient.post('/update/attribute', {
                currency: getCurrencyLocal().id
            })
                .then(response => {
                    // console.log('تم تغيير العملة بنجاح');
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        toast.error('' + error?.response?.data?.message);
                    }
                });
            }
        };

            window.addEventListener('changedCurrency', handleChangedCurrency);

            return () => {
                window.removeEventListener('changedCurrency', handleChangedCurrency);
            };

    }, []);

    useEffect(() => {
        const handleChangedlang = () => {

                if(getLocalLang()&& getLocalLang().code !== 'ar'){
                    document.documentElement.setAttribute('dir', 'ltr');
                }else{
                    document.documentElement.setAttribute('dir', 'rtl');
                }



            if(getToken() && getToken()!=='' && getToken() !== null){
                axiosClient.post('/update/attribute', {
                    lang: getLocalLang().code??'ar'
                })
                    .then(response => {
                        // console.log('تم تغيير اللفة بنجاح');
                    })
                    .catch(error => {
                        if (error?.response?.data?.message) {
                            toast.error('' + error?.response?.data?.message);
                        }
                    });
            }

        };
            window.addEventListener('LangChanged', handleChangedlang);

            return () => {
                window.removeEventListener('LangChanged', handleChangedlang);
            };

    }, []);



    return (
        <Suspense fallback={
            <>
                <Loader />
            </>
        }>
            <HelmetProvider>
                <ContextProvider>
                    <Provider store={store}>
                        <RouterProvider router={Router} />
                        <SEOComponent/>
                        <FirstData/>
                    </Provider>
                </ContextProvider>
            </HelmetProvider>
            <ToastContainer autoClose={2000} />
        </Suspense>
    );
}

createRoot(document.getElementById('root')).render(<App />);
